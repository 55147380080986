document.addEventListener('DOMContentLoaded', function() {
		const backdrop = document.createElement('div');
		backdrop.classList.add('image-backdrop', 'gallery-image');
		document.body.appendChild(backdrop);

		clickOn('section.gallery .swiper-slide img', (event) => {
			backdrop.innerHTML = event.target.outerHTML;
			backdrop.classList.add('show');
			backdrop.classList.remove('close');
		})
		clickOn('.image-backdrop.gallery-image, .image-backdrop.gallery-image *', (event) => {
			backdrop.classList.remove('show');
			backdrop.classList.add('close');
			setTimeout(() => {
				backdrop.classList.remove('close');
			}, 500);
		})
})
function clickOn(element, callback) {
	list[element] = callback;
}
document.addEventListener("click", (event) => {
	Object.keys(list).forEach((selector) => {
		if (event.target.matches(selector)) {
			list[selector](event);
		}
	});
})
const list = {
}